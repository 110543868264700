@import 'bootswatch/dist/united/_variables.scss';
@import 'tippy.js/dist/tippy.css';

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

html {
    height: -webkit-fill-available;
}

.table {
    --bs-table-bg: inherit;
}

.ng-dropdown-panel {
    width: auto !important;
}

.fc-license-message {
    display: none;
}
.fc-view-harness {
    z-index: 0 !important;
}

tr[ng-reflect-router-link] {
    cursor: pointer;
}

.min-width-0 {
    min-width: 0;
}

.tab-content {
    padding: 15px 0;
}

/* Hover state on all cards  */
.card.card-clickable,
table-data .card {
    cursor: pointer;

    &.card-selected,
    &:hover {
        border: 1px solid #2c3e50;
    }
}
.card.card-clickable.card-disabled {
    position: relative;
    opacity: 0.8;
    &:hover {
        border: 1px solid rgba(0, 0, 0, 0.125);
    }

    ::after {
        content: '';
        position: absolute;
        cursor: not-allowed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: repeating-linear-gradient(
            135deg,
            rgba(100, 100, 100, 0),
            rgba(100, 100, 100, 0) 10px,
            rgba(100, 100, 100, 0.025) 10px,
            rgba(100, 100, 100, 0.025) 20px
        );
    }
}

.card.card-clickable {
    .card-selected-icon {
        position: absolute;
        top: 3px;
        right: 7px;
    }

    .card-img-top.card-img-top-center {
        width: 50%;
        margin: 25% 25% 10% 25%;
    }
    .media img {
        max-width: 48px;
    }
    .two-lines {
        display: table;
    }
    .two-lines > * {
        line-height: 1.5em;
        height: 3em;
        vertical-align: middle;
        display: table-cell;
        width: 100%;
    }
}

/* All links should show a pointer */
[href],
a {
    cursor: pointer;
}
.navbar [ng-reflect-router-link] {
    cursor: pointer;
}

/* Images */
img.avatar {
    text-align: center;
    margin: 10px auto 25px auto;
    border-radius: 100%;
    height: 150px;
    border: 1px solid #aaa;
}

.img-xs,
.img-sm,
.img-md,
.img-lg {
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 2px;
    position: relative;
    &:before {
        content: '';
        float: left;
        padding-top: 100%; /* initial ratio of 1:1*/
    }
}
.img-banner-xs,
.img-banner-sm,
.img-banner-md,
.img-banner-lg {
    border-radius: 10%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 2px;
}

.img-xs,
.img-banner-xs {
    height: 32px;
    width: 32px;
}
.img-sm,
.img-banner-sm {
    height: 48px;
    width: 48px;
}
.img-md,
.img-banner-md {
    height: 64px;
    width: 64px;
}
.img-lg,
.img-banner-lg {
    height: 128px;
    width: 128px;
}
.img-xl,
.img-banner-xl {
    height: 256px;
    width: 256px;
}

.img-banner-xs {
    width: 116px;
}
.img-banner-sm {
    width: 174px;
}
.img-banner-md {
    width: 232px;
}
.img-banner-lg {
    width: 464px;
}
.img-banner-xl {
    width: 928px;
}

.image-upload-round {
    border-radius: 100%;
    overflow: hidden;
    width: 80%;
    margin: 5% 10% 5% 10%;
    border: 0.2vw solid #ccc;
}

.img-holder {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    max-width: 256px;
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid #ccc;
}

/* Globals */
textarea {
    resize: vertical;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input::-webkit-calendar-picker-indicator,
input::-webkit-clear-button {
    display: none;
}
/*input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration { display: none; }
*/

.btn > div,
.btn i.fa {
    pointer-events: none;
}

/* Table details */
tr .muted-extra-line {
    overflow: hidden;
    color: #bbbbbb;
    height: 1.6em;
}

.message .summary {
    position: relative;
    background-color: #f5f5f5;
    cursor: pointer;
}
.message .summary .content {
    width: 100px;
    height: 17px;
    line-height: 17px;
    text-align: center;
    background-color: whitesmoke;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.message .summary .line {
    border-bottom: 1px solid #e8e8e8;
    width: 100%;
    height: 9px;
    display: block;
}

.message .mail-renderbox {
    position: relative;
}
.message .mail-renderbox .gmail_quote {
    display: none;
}
.message .mail-renderbox.mail-renderbox-full .gmail_quote {
    display: block;
}
.message .mail-renderbox .mail-renderbox-dropdown {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
}
.message .mail-renderbox.mail-renderbox-popout {
    border: 5000px solid rgba(0, 0, 0, 0.75);
    background-color: white;
    background-clip: padding-box;
    margin: -5000px;
    z-index: 10000;
    position: fixed;
    left: 15%;
    right: 15%;
    top: 5%;
    bottom: 0%;
    overflow: scroll;
}

/* Form placeholder */
.form-control::placeholder {
    opacity: 0.5 !important;
}

.fc .fc-event.flexible,
.fc .fc-event.definitive {
    background-color: #ff9800;
    border-color: #ab772c;
}
.fc .fc-event.unavailable {
    background-color: #ff6e74;
    border-color: #ff6e74;
}
.fc .fc-event.available {
    background-color: #7bd148;
    border-color: #7bd148;
}
.fc .fc-event.unavailable.repeat {
    background-color: #dc2127;
    border-color: #dc2127;
}
.fc .fc-event.available.repeat {
    background-color: #2f8500;
    border-color: #2f8500;
}

.fc .fc-event.dark {
    background-color: $dark;
    border-color: $dark;
}
.fc .fc-event.dark-bg-subtle {
    background-color: var(--bs-dark-bg-subtle);
    border-color: $dark;
    outline: 1px solid $dark;
}
.fc .fc-event.light {
    background-color: $light;
    border-color: $light;
}
.fc .fc-event.light-bg-subtle {
    background-color: var(--bs-light-bg-subtle);
    border-color: $light;
    outline: 1px solid $light;
}
.fc .fc-event.secondary {
    background-color: $secondary;
    border-color: $secondary;
}
.fc .fc-event.secondary-bg-subtle {
    background-color: var(--bs-secondary-bg-subtle);
    border-color: $secondary;
    outline: 1px solid $secondary;
}
.fc .fc-event.info {
    background-color: $info;
    border-color: $info;
}
.fc .fc-event.info-bg-subtle {
    background-color: var(--bs-info-bg-subtle);
    border-color: darken($info, 20%);
    outline: 1px solid darken($info, 20%);
}
.fc .fc-event.success {
    background-color: $success;
    border-color: $success;
}
.fc .fc-event.success-bg-subtle {
    background-color: var(--bs-success-bg-subtle);
    border-color: $success;
    outline: 1px solid $success;
}
.fc .fc-event.warning {
    background-color: lighten($warning, 5%);
    border-color: lighten($warning, 5%);
}
.fc .fc-event.warning-bg-subtle {
    background-color: var(--bs-warning-bg-subtle);
    border-color: lighten($warning, 5%);
    outline: 1px solid lighten($warning, 5%);
}
.fc .fc-event.danger {
    background-color: lighten($danger, 15%);
    border-color: lighten($danger, 15%);
}
.fc .fc-event.danger-bg-subtle {
    background-color: var(--bs-danger-bg-subtle);
    border-color: lighten($danger, 15%);
    outline: 1px solid lighten($danger, 15%);
}
.fc .fc-event.primary {
    background-color: lighten($primary, 10%);
    border-color: lighten($primary, 10%);
}
.fc .fc-event.primary-bg-subtle {
    background-color: var(--bs-primary-bg-subtle);
    border-color: lighten($primary, 10%);
    outline: 1px solid lighten($primary, 10%);
}

/* Events which can no longer be edited */
.fc .fc-event.blocked {
    opacity: 0.8;

    ::after {
        content: '';
        position: absolute;
        cursor: not-allowed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: repeating-linear-gradient(
            135deg,
            rgba(100, 100, 100, 0),
            rgba(100, 100, 100, 0) 10px,
            rgba(100, 100, 100, 0.05) 10px,
            rgba(100, 100, 100, 0.05) 20px
        );
    }
}

.fc-today {
    background-color: rgba(0, 130, 255, 0.1) !important;
}

// Ace-editor / Json editor
.ace_editor.ace-jsoneditor {
    min-height: 500px;
}

// BsDateConfig
.bs-datepicker-config-today {
    // background-color: rgba(185, 185, 185, 0.15);
    border: 1px solid rgba(84, 112, 139, 0.9);
    // background: #54708b;
}
